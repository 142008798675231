import { useEffect, useState } from 'react'
import { useNodesList } from '@/api/generated/hooks'
import { NodeRes, SiteRes } from '@/api/generated/types'
import PromiseNodeNumberContainer from '@/containers/app/PromiseNodeNumberContainer'

export type NodeWithBuildingNodeType = {
  /**
   * 노드 정보
   */
  node: NodeRes
  /**
   * 노드의 건물 노드 정보
   */
  nodeBuilding: NodeRes | undefined
}

interface usePromiseNodeProps {
  site: SiteRes | undefined
}

const usePromiseNode = ({ site }: usePromiseNodeProps) => {
  const {
    isReady: isPromiseNodeNumberContainerReady,
    promiseNodeNumberUpdatedAt,
    getPromiseNodeNumber,
    updatePromiseNodeNumber,
    removePromiseNodeNumber
  } = PromiseNodeNumberContainer.useContainer()
  const [isReady, setIsReady] = useState(false)
  const [promiseNode, setPromiseNode] = useState<NodeRes>()
  const [promiseNodeBuilding, setPromiseNodeBuilding] = useState<NodeRes>()
  const { data: nodeList } = useNodesList({
    query: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 60 * 60 * 1000
    }
  })

  const getNode = (
    nodeList: NodeRes[],
    nodeNumber: string | undefined | null
  ): NodeWithBuildingNodeType | undefined => {
    if (!nodeNumber) {
      return
    }
    const nodeFound = nodeList?.find((node) => node.nodeNumber === nodeNumber)
    if (!nodeFound) {
      return undefined
    }

    if (!nodeFound.building) {
      return {
        node: nodeFound,
        nodeBuilding: undefined
      }
    }
    const nodeBuildingFound = nodeList.find((node) => node.building === nodeFound.building && !node.floor)
    if (!nodeBuildingFound) {
      return undefined
    }

    return {
      node: nodeFound,
      nodeBuilding: nodeBuildingFound
    }
  }

  useEffect(() => {
    if (isPromiseNodeNumberContainerReady && nodeList && site) {
      const promiseNodeNumber = getPromiseNodeNumber(site.id)
      const nodeResult = getNode(nodeList, promiseNodeNumber)
      if (nodeResult) {
        const { node, nodeBuilding } = nodeResult
        setPromiseNode(node)
        setPromiseNodeBuilding(nodeBuilding)
      } else {
        setPromiseNode(undefined)
        setPromiseNodeBuilding(undefined)
      }
      setIsReady(true)
    }
  }, [isPromiseNodeNumberContainerReady, nodeList, site, promiseNodeNumberUpdatedAt])

  const updatePromiseNode = (promiseNodeNumber: string | undefined): NodeWithBuildingNodeType | undefined => {
    if (!site || !nodeList) {
      return undefined
    }
    const nodeResult = getNode(nodeList, promiseNodeNumber)
    if (nodeResult) {
      const { node, nodeBuilding } = nodeResult
      setPromiseNode(node)
      setPromiseNodeBuilding(nodeBuilding)
      updatePromiseNodeNumber(site.id, promiseNodeNumber)
    } else {
      setPromiseNode(undefined)
      setPromiseNodeBuilding(undefined)
      removePromiseNodeNumber(site.id)
    }
    return nodeResult
  }

  return {
    isReady,
    promiseNode,
    promiseNodeBuilding,
    updatePromiseNode
  }
}

export const getNodeName = (node: NodeRes, nodeBuilding: NodeRes | undefined) => {
  if (nodeBuilding) {
    return nodeBuilding.name + ' ' + node.name
  } else {
    return node.name
  }
}

export default usePromiseNode
