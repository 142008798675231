import { useEffect, useRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import { NodeRes, SiteRes } from '@/api/generated/types'
import useDropDown, { DropDownContentType } from '@/hooks/common/useDropDown'
import { GeoMapControlsType, MapEventEnum, MarkerEnum, MarkerStyle } from '@/containers/common/GeoMapContainer'
import DataUtils from '@/utils/design-system/dataUtils'
import usePromiseNode from '@/hooks/usePromiseNode'

const useSiteMapBottomSheetContent = ({
  site,
  geoMapControls,
  nodeList
}: {
  site?: SiteRes
  geoMapControls: GeoMapControlsType
  nodeList?: NodeRes[]
}) => {
  const { drawMarker, geoMap, isGeoMapLoaded, mapSite, panTo, setMapSite, removeMarker } = geoMapControls
  const destinationDropDownControls = useDropDown<NodeRes>({
    onChange(selectedItem: DropDownContentType<NodeRes> | undefined) {
      const selectedDestinationNode = selectedItem?.data
      if (selectedDestinationNode) {
        setDestinationNode(selectedDestinationNode)
        panTo({ longitude: selectedDestinationNode.longitude, latitude: selectedDestinationNode.latitude })
      }
    }
  })
  const { isReady: isPromiseNodeReady, promiseNode, promiseNodeBuilding } = usePromiseNode({ site })
  const detailDestinationDropDownControls = useDropDown<NodeRes>()
  const prevSelectedDestinationMarkerRef = useRef<NodeRes>()
  const isCanUseMap = geoMap && isGeoMapLoaded && nodeList && mapSite
  const destinationList = nodeList?.filter((node) => DataUtils.isNullOrUndefined(node.floor))
  const selectedDestinationNode = destinationDropDownControls.selectedItem?.data
  const isHasDetailDestination = selectedDestinationNode?.building
  const selectedDetailDestinationNode = detailDestinationDropDownControls.selectedItem?.data
  const selectedPromiseNode = isHasDetailDestination ? selectedDetailDestinationNode : selectedDestinationNode

  const setDestinationNode = (selectedNode: NodeRes) => {
    const detailDestinationList = nodeList?.filter((node) => node.building === selectedNode?.building && node.floor)
    const optionList = detailDestinationList?.map((node) => ({ id: node.nodeNumber, content: node.name, data: node }))
    detailDestinationDropDownControls.setSelectedItem(undefined)
    detailDestinationDropDownControls.setOptionList(optionList)
  }

  /**
   * 1. 선택되지 않은 노드들만 일반 목적지 마커로 그립니다.
   * 2. 선택된 노드만 활성화 목적지 마커로 그립니다.
   */
  const drawDestinationMarker = (node: NodeRes, isActive: boolean) => {
    if (!isCanUseMap) return
    drawMarker({
      zIndex: 0,
      longitude: node.longitude,
      latitude: node.latitude,
      markerType: isActive ? MarkerEnum.SelectedNode : MarkerEnum.NotSelectedNode,
      markerMarkup: isActive
        ? MarkerStyle[MarkerEnum.SelectedNode](node.name)
        : MarkerStyle[MarkerEnum.NotSelectedNode](node.name),
      mapEventType: MapEventEnum.ClickSelectNode,
      onClickListener: () => {
        panTo({ longitude: node.longitude, latitude: node.latitude })
        destinationDropDownControls.setSelectedItem({
          id: node.nodeNumber,
          content: node.name,
          data: node
        })
        setDestinationNode(node)
      }
    })
  }

  useEffect(() => {
    if (!site) return
    setMapSite(site)
  }, [site])

  useEffect(() => {
    if (site && site.centerLatitude && site.centerLongitude && nodeList && isPromiseNodeReady) {
      geoMapControls.initGeoMap({
        longitude: site.centerLongitude,
        latitude: site.centerLatitude,
        mapTypeControl: false
      })
      const optionList = destinationList?.map((node) => ({ id: node.nodeNumber, content: node.name, data: node }))
      destinationDropDownControls.setOptionList(optionList)
    }
  }, [site, nodeList, isPromiseNodeReady])
  useEffect(() => {
    if (!isCanUseMap) return
    destinationList?.forEach((node) => drawDestinationMarker(node, false))

    return () => {
      removeMarker({ removeMarkerTypes: [MarkerEnum.NotSelectedNode] })
    }
  }, [geoMap, isGeoMapLoaded, mapSite])

  useEffect(() => {
    if (!isCanUseMap) return
    // 이전 선택된 활성화 마커를 지우고 일반 마커로 그립니다.
    if (prevSelectedDestinationMarkerRef.current) {
      const prevSelectedNode = prevSelectedDestinationMarkerRef.current
      removeMarker({ removeMarkerTypes: [MarkerEnum.SelectedNode] })
      drawDestinationMarker(prevSelectedNode, false)
    }

    if (isEmpty(destinationDropDownControls.selectedItem)) return
    const selectedNode = destinationDropDownControls.selectedItem.data
    if (isEmpty(selectedNode)) return
    // 선택된 노드는 Destination 마커로 그려졌었기 때문에 기존마커를 지우고 SelectedNode 마커로 다시 그립니다.
    removeMarker({
      removeMarkerTypes: [MarkerEnum.NotSelectedNode],
      removeMarkerCoordinate: { longitude: selectedNode.longitude, latitude: selectedNode.latitude }
    })
    drawDestinationMarker(selectedNode, true)
    return () => {
      prevSelectedDestinationMarkerRef.current = selectedNode
    }
  }, [geoMap, isGeoMapLoaded, mapSite, destinationDropDownControls.selectedItem])

  useEffect(() => {
    if (isPromiseNodeReady) {
      if (promiseNode) {
        if (promiseNodeBuilding) {
          destinationDropDownControls.setSelectedItem({
            id: promiseNodeBuilding.nodeNumber,
            content: promiseNodeBuilding.name,
            data: promiseNodeBuilding
          })
          detailDestinationDropDownControls.setSelectedItem({
            id: promiseNode.nodeNumber,
            content: promiseNode.name,
            data: promiseNode
          })
        } else {
          destinationDropDownControls.setSelectedItem({
            id: promiseNode.nodeNumber,
            content: promiseNode.name,
            data: promiseNode
          })
          setDestinationNode(promiseNode)
        }
      } else {
        destinationDropDownControls.setSelectedItem(undefined)
      }
    }
  }, [promiseNode, isPromiseNodeReady])

  return {
    isHasDetailDestination,
    selectedPromiseNode,
    destinationDropDownControls,
    detailDestinationDropDownControls
  }
}

export default useSiteMapBottomSheetContent
