import useBottomSheetLegacy from '@/hooks/common/useBottomSheetLegacy'
import { useEffect, useState } from 'react'
import { useNodesList } from '@/api/generated/hooks'
import { NodeRes, SiteRes } from '@/api/generated/types'
import { ToastControlsType } from '@/hooks/common/useToast'
import usePromiseNode from '@/hooks/usePromiseNode'

export enum FlagLoadStatus {
  Loading = 'Loading',
  True = 'True',
  False = 'False'
}

interface UseSiteMapProps {
  site?: SiteRes
  toastControls: ToastControlsType
}

const useSiteMap = ({ toastControls, site }: UseSiteMapProps) => {
  const siteMapBottomSheetControls = useBottomSheetLegacy()
  const [isSelectedPromise, setIsSelectedPromise] = useState<FlagLoadStatus>(FlagLoadStatus.Loading)
  const { isReady, promiseNode, promiseNodeBuilding, updatePromiseNode } = usePromiseNode({ site })

  const { data: nodeList } = useNodesList({
    query: {
      cacheTime: 60 * 60 * 1000,
      staleTime: 60 * 60 * 1000
    }
  })

  const setPromise = (promiseNodeNumber: string | undefined) => {
    const result = updatePromiseNode(promiseNodeNumber)
    setIsSelectedPromise(result ? FlagLoadStatus.True : FlagLoadStatus.False)
  }

  const handleSavePromiseNode = (selectedPromiseNode: NodeRes) => {
    setPromise(selectedPromiseNode.nodeNumber)
    siteMapBottomSheetControls.handleClose()
    toastControls.addToast('약속 장소가 설정되었어요.')
  }

  const handleSiteMapOpen = () => {
    siteMapBottomSheetControls.handleOpen()
  }

  useEffect(() => {
    if (isReady) {
      setIsSelectedPromise(promiseNode ? FlagLoadStatus.True : FlagLoadStatus.False)
    }
  }, [isReady])

  return {
    isReady,
    siteMapBottomSheetControls,
    promiseNode,
    promiseNodeBuilding,
    nodeList,
    handleSavePromiseNode,
    handleSiteMapOpen,
    isSelectedPromise
  }
}

export default useSiteMap
