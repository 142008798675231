import BottomSheet from '@/components/common/design-system/BottomSheet'
import { BottomSheetControlsType } from '@/hooks/common/useBottomSheet'
import { SiteRes } from '@/api/generated/types'
import Icon from '@/components/common/Icon'
import { IconNamesEnum } from '@/constants/iconNames.enum'

interface SiteChangeBottomSheetProps {
  bottomSheetControls: BottomSheetControlsType
  currentSite: SiteRes | undefined
  siteList?: SiteRes[]
  onSiteClick: (site: SiteRes) => void
}

const SiteChangeBottomSheet = ({
  bottomSheetControls,
  currentSite,
  siteList,
  onSiteClick
}: SiteChangeBottomSheetProps) => {
  const handleSiteClick = (site: SiteRes) => {
    onSiteClick(site)
    bottomSheetControls.handleClose()
  }

  return (
    <>
      <BottomSheet
        bottomSheetControls={bottomSheetControls}
        height="h-[80vh]"
        handleDimClick={() => bottomSheetControls.handleClose()}>
        <BottomSheet.Header bottomSheetControls={bottomSheetControls} className="w-full">
          <div className="flex items-center">
            <div className="flex h-24 min-w-64 items-center justify-start">
              <button onClick={bottomSheetControls.handleClose}>
                <Icon name={IconNamesEnum.ChevronLeft} />
              </button>
            </div>
            <div className="body1 flex flex-1 items-center justify-center truncate text-center font-bold text-gray">
              <span>배달 지역 변경</span>
            </div>
            <div className="flex h-24 min-w-64 items-center justify-end" />
          </div>
        </BottomSheet.Header>
        <div className="mt-30 flex h-full flex-col gap-16 overflow-y-scroll px-16">
          <div className="body3 text-gray-400">
            <p>배달 지역을 변경할 경우 배달 정보가 달라져</p>
            <p>주문을 처음부터 진행해야 할 수 있어요</p>
          </div>
          <div className="flex flex-col gap-12">
            <button
              className="body1 flex h-65 w-full justify-between rounded-xs border-2 p-20"
              onClick={bottomSheetControls.handleClose}>
              <span className="truncate">{currentSite?.name}</span>
              <Icon name={IconNamesEnum.Check} className="h-20 w-20 text-primary-300" />
            </button>
            {siteList?.map((site) => (
              <button
                key={site.id}
                className="body1 flex h-65 w-full rounded-xs border-2 p-20"
                onClick={() => handleSiteClick(site)}>
                <span className="truncate">{site?.name}</span>
              </button>
            ))}
          </div>
        </div>
      </BottomSheet>
    </>
  )
}

export default SiteChangeBottomSheet
