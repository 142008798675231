import BottomSheetLegacy from '@/components/common/design-system/BottomSheetLegacy'
import Icon from '@/components/common/Icon'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import DropDown, { searchTextHighLight } from '@/components/common/design-system/DropDown'
import { NodeRes, SiteRes } from '@/api/generated/types'
import CTAButton from '@/components/common/design-system/CTAButton'
import Button, { ButtonSizeEnum, ButtonThemeEnum } from '@/components/common/design-system/Button'
import GeoMap from '@/components/geo-map/GeoMap'
import useSiteMapBottomSheetContent from '@/hooks/useSiteMapBottomSheetContent'
import { BottomSheetLegacyControlsType } from '@/hooks/common/useBottomSheetLegacy'
import { useMemo } from 'react'
import GeoMapContainer from '@/containers/common/GeoMapContainer'
import usePromiseNode from '@/hooks/usePromiseNode'
import SiteChangeBottomSheet from '@/components/home/SiteChangeBottomSheet'
import useBottomSheet from '@/hooks/common/useBottomSheet'
import { ComponentUtils } from '@/utils/design-system/componentUtils'

const extractAddress = (nodeList?: NodeRes[], targetNode?: NodeRes) => {
  return nodeList?.find((node) => node.nodeNumber === targetNode?.nodeNumber ?? '')?.address
}

type SiteMapBottomSheetProps = {
  bottomSheetControls: BottomSheetLegacyControlsType
  site?: SiteRes
  siteList?: SiteRes[]
  nodeList?: NodeRes[]
  onSavePromiseNode: (selectedPromiseNode: NodeRes) => void
  onNewSiteNameClick: (site: SiteRes) => void
}

const SiteMapBottomSheet = (props: SiteMapBottomSheetProps) => {
  return (
    <BottomSheetLegacy bottomSheetControls={props.bottomSheetControls} height="h-[80vh]">
      <SiteMapBottomSheetContent {...props} />
    </BottomSheetLegacy>
  )
}

const SiteMapBottomSheetContent = ({
  bottomSheetControls,
  site,
  siteList,
  nodeList: nodeListOri,
  onSavePromiseNode,
  onNewSiteNameClick
}: SiteMapBottomSheetProps) => {
  const geoMapControls = GeoMapContainer.useContainer()
  const siteChangeBottomSheetControls = useBottomSheet()

  const { isReady: isPromiseNodeReady, updatePromiseNode } = usePromiseNode({ site })

  const siteListWithoutCurrent = siteList?.filter((item) => item.name !== site?.name)
  const hasMultipleSite = siteListWithoutCurrent && siteListWithoutCurrent.length > 0

  const nodeList = useMemo(() => {
    if (!nodeListOri) {
      return undefined
    }
    const toStringForCompare = (name: string): string => {
      const indexOfComma = name.indexOf(',')
      const indexOfRange = name.indexOf('~')

      let result = name
      if (indexOfComma === -1 && indexOfRange === -1) {
        result = name
      } else if (indexOfComma !== -1 && indexOfRange !== -1) {
        const index = indexOfComma < indexOfRange ? indexOfComma : indexOfRange
        result = name.slice(0, index)
      } else if (indexOfComma !== -1) {
        result = name.slice(0, indexOfComma)
      } else {
        result = name.slice(0, indexOfRange)
      }

      return result.padStart(4, '0')
    }
    return nodeListOri?.sort((a, b) => {
      const resultA = toStringForCompare(a.name)
      const resultB = toStringForCompare(b.name)
      if (resultA === resultB) {
        return 0
      }
      return resultA > resultB ? 1 : -1
    })
  }, [nodeListOri])

  const {
    destinationDropDownControls,
    detailDestinationDropDownControls,
    isHasDetailDestination,
    selectedPromiseNode
  } = useSiteMapBottomSheetContent({ site, geoMapControls, nodeList })

  const handleSavePromiseButtonClick = () => {
    if (!selectedPromiseNode || !site || !isPromiseNodeReady) {
      return
    }
    updatePromiseNode(selectedPromiseNode.nodeNumber)
    onSavePromiseNode(selectedPromiseNode)
  }

  const handleSelectedPromiseNodeClick = () => {
    if (hasMultipleSite) {
      siteChangeBottomSheetControls.handleOpen()
    }
  }

  const handleNewSiteNameClick = async (site: SiteRes) => {
    onNewSiteNameClick(site)
  }

  const extractedAddress = extractAddress(nodeList, selectedPromiseNode)

  return (
    <>
      <BottomSheetLegacy.Header bottomSheetControls={bottomSheetControls} isShowCloseButton>
        약속 장소 설정
      </BottomSheetLegacy.Header>
      <div className="flex h-28 w-full items-center justify-center gap-4 text-warning">
        <Icon className="h-20 w-20" name={IconNamesEnum.Information} />
        <span className="body3 font-medium">뉴비는 정해진 장소에서만 만날 수 있어요</span>
      </div>
      <GeoMap className="mt-8 h-full w-full bg-gray-300" />
      <div className="w-full py-16 px-16">
        <div
          className={ComponentUtils.cn(
            'flex flex-1 items-center gap-2',
            hasMultipleSite ? 'cursor-pointer' : undefined
          )}
          onClick={handleSelectedPromiseNodeClick}>
          <Icon name={IconNamesEnum.LocationFilledFalse} className="h-20 w-20 flex-shrink-0" />
          <span className="body2 truncate">{site?.name}</span>
          {hasMultipleSite && <Icon name={IconNamesEnum.ChevronDown} className="h-32 w-32 flex-shrink-0 p-8" />}
        </div>
        {/*todo: margin-left의 값을 w-22로 하드코딩 하지 않는 방법으로 개선*/}
        {extractedAddress && <p className="body3 ml-22 text-gray-500">{extractedAddress}</p>}
        <div className="h-17 w-full" />
        <DropDown defaultItemText="약속 장소 설정" dropDownProps={destinationDropDownControls}>
          <DropDown.Search searchPlaceHolder="건물명을 입력하세요." dropDownProps={destinationDropDownControls} />
          <DropDown.List>
            {destinationDropDownControls.searchFilterList?.map((item) => {
              return (
                <DropDown.Item
                  dropDownProps={destinationDropDownControls}
                  key={item.id}
                  itemContent={{
                    id: item.id,
                    content: <span>{searchTextHighLight(item.content, destinationDropDownControls.searchText)}</span>
                  }}
                />
              )
            })}
          </DropDown.List>
        </DropDown>
        {isHasDetailDestination && (
          <>
            <div className="h-10 w-full" />
            <DropDown defaultItemText="상세 목적지 설정" dropDownProps={detailDestinationDropDownControls}>
              <DropDown.Search
                searchPlaceHolder="검색어를 입력하세요."
                dropDownProps={detailDestinationDropDownControls}
              />
              <DropDown.List>
                {detailDestinationDropDownControls.searchFilterList?.map((item) => {
                  return (
                    <DropDown.Item
                      dropDownProps={detailDestinationDropDownControls}
                      key={item.id}
                      itemContent={{
                        id: item.id,
                        content: (
                          <span>{searchTextHighLight(item.content, detailDestinationDropDownControls.searchText)}</span>
                        )
                      }}
                    />
                  )
                })}
              </DropDown.List>
            </DropDown>
          </>
        )}
      </div>
      <div className="h-16 w-full" />
      <CTAButton>
        <Button
          full
          theme={ButtonThemeEnum.Primary}
          size={ButtonSizeEnum.ExtraLarge}
          disabled={!selectedPromiseNode}
          onClick={handleSavePromiseButtonClick}>
          <span className="body1 font-bold">선택한 위치로 설정</span>
        </Button>
      </CTAButton>
      <SiteChangeBottomSheet
        bottomSheetControls={siteChangeBottomSheetControls}
        currentSite={site}
        siteList={siteListWithoutCurrent}
        onSiteClick={(site) => handleNewSiteNameClick(site)}
      />
    </>
  )
}

export default SiteMapBottomSheet
